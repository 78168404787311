import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import HcpdPageHero from '../../components/HealthCareProviderDirectory/HcpdPageHero'
import { ProvideAuth } from '../../components/Auth/useAuth'
import ApolloWrapper from '../../components/Auth/ApolloWrapper'
import EditRegistration from '../../components/HealthCareProviderDirectory/EditRegistration'
import LogOutButton from '../../components/Auth/LogOutButton'
import SEO from '../../components/SEO'

export const query = graphql`
  {
    prismicHcpdEditRegistrationPage {
      data {
        body_text {
          richText
        }
        title {
          text
        }
        display_name
        facebook_image {
          url
        }
        meta_description
        meta_keywords
        meta_title {
          text
        }
        twitter_image {
          url
        }
      }
    }
  }
`

const EditRegistrationPage = ({ data }) => {
  const doc = data.prismicHcpdEditRegistrationPage?.data
  if (!doc) {
    return null
  }
  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.title?.text
    ? doc.title.text
    : 'Edit Registration'

  return (
    <ProvideAuth>
      <ApolloWrapper>
        <SEO
          seo={{
            description: doc.meta_description,
            keywords: doc.meta_keywords,
            title: seoTitle,
            twitter_image: twitterImage,
            facebook_image: facebookImage,
          }}
        />
        <Layout>
          <LogOutButton />
          <HcpdPageHero
            title={doc.title?.text ?? 'Edit Registration'}
            body={doc.body_text}
            showLogoutButton
          />
          <EditRegistration />
        </Layout>
      </ApolloWrapper>
    </ProvideAuth>
  )
}

export default EditRegistrationPage
