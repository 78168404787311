import React from 'react'

import * as Styled from './styles/LogOutButton.styles'
import SubmitButton from './SubmitButton'
import { RegionContainer } from '../styles/Utility.styles'
import { useAuth } from './useAuth'

const LogOutButton = () => {
  const auth = useAuth()

  const LogOutAction = () => {
    auth.signOut()
  }

  return (
    <RegionContainer>
      {auth.user && (
        <Styled.ButtonRow>
          <SubmitButton text="Log Out" action={e => LogOutAction(e)} />
        </Styled.ButtonRow>
      )}
    </RegionContainer>
  )
}

export default LogOutButton
