import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import RegistrationForm from '../Auth/RegistrationForm'
import { useAuth } from '../Auth/useAuth'

const LOOKUP_USER_BY_COGNITO_HASH = gql`
  query queryByCognitoHashGSI($cognito_hash: String) {
    queryByCognitoHashGSI(cognito_hash: $cognito_hash) {
      items {
        pk
      }
    }
  }
`

const EditRegistration = () => {
  const auth = useAuth()
  const [hash, setHash] = useState(null)
  const [userPk, setUserPk] = useState(null)

  useEffect(() => {
    if (auth.user) {
      setHash(auth.user.attributes.sub)
    } else if (auth.user === false) {
      navigate('/health-care-provider-directory/login')
    }
  }, [auth])

  const { loading, error, data } = useQuery(LOOKUP_USER_BY_COGNITO_HASH, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: { cognito_hash: hash ? hash : 'NA' },
  })

  useEffect(() => {
    setUserPk(data?.queryByCognitoHashGSI?.items?.slice(0, 1).pop().pk)
  }, [data])

  if (loading) {
    return <p>Loading ...</p>
  }
  if (error) {
    // console.log(error.graphQLErrors)
    return <p>Error {error.graphQLErrors} ...</p>
  }

  if (!hash) {
    return null
  }
  return <>{data && userPk && <RegistrationForm user={userPk} />}</>
}

export default EditRegistration
